import React, { useState, useEffect, useMemo } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import request from "services/request";
import { useUserData } from "contexts/AuthContextManagement";
import CustomWidget from "components/customWidget/index";
import useFetch from "customHooks/useFetch";
import { utcTimezoneFormat,  } from "common/utils";
import getSampleForecastData from "../../data/forecast.json";
import { 
  COLUMNS, forecastParseData, setWeathersWidgetData, getForecastColumns, 
  getDates, nameMapping, priorityOrder, calculateSumsPerDate, updateValues, generateDates
} from "./utils";


function Forecast({ formData, onPreviousWeek, onCurrentWeek, onNextWeek, activeWeek, isNextDisabled }) {
  const { t } = useTranslation();

  const {
    selectedRestaurant,
    isRestaurantLoaded,
    hasRetaurants,
    selectedRestaurantId,
  } = useUserData();
  // const { loading: weatherLoading, data: weatherData } = useFetch("weather", {
  //   start_date: formData?.start_date,
  //   end_date: formData?.end_date,
  //   location_id: selectedRestaurant?.location_id,
  // });
  
  const [temperatures, setTemperatures] = useState([
    { name: "morning" },
    { name: "noon" },
    { name: "night" },
  ]);
  const [dates, setDates] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [occupancyData, setOccupancyData] = useState([]);
  const [foodcostData, setFoodcostData] = useState([]);
  const [forecastData, setForecastData] = useState(false);
  const [eventsData, setEventsData] = useState([]);
  const [averageGroupRevenueData, setAverageGroupRevenueData] = useState([]);
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [eventLoader, setEventLoader] = useState(true);
  const [weatherData, setWeatherData] = useState(null);
  const [weatherLoading, setWeatherLoading] = useState(false);

  useEffect(() => {
    if (!isRestaurantLoaded || !hasRetaurants) {
      return;
    }
    if (weatherLoading) {
      return;
    }
    try {
      setTemperatures(() =>
        setWeathersWidgetData(weatherData?.days, temperatures)
      );
    } catch (error) {
      console.log(error);
    }
  }, [weatherData, weatherLoading, isRestaurantLoaded, hasRetaurants]);

  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) {
      const today = new Date().toISOString().split('T')[0];
      const dynamicDates = generateDates(formData.start_date, formData.end_date);
      setDates(dynamicDates);

      const filteredData = getSampleForecastData.map((item) => {
        const originalDates = Object.keys(item).filter(key => key !== 'name');
        return updateValues(item, originalDates, today, dynamicDates);
      });

      setCurrentItems(filteredData);
      setLoadingDetails(false);
    }
  }, [isRestaurantLoaded, formData]);

  const cols = useMemo(() => getForecastColumns(t, dates), [t, formData, dates]);

  useEffect(() => {
    generateTableColumns(cols)
  },[cols])

  useEffect(() => {
    if(selectedRestaurant) {
      setCurrentItems([]);
      setLoadingDetails(true);
      getWeatherData();
      getFoodcastData();
      getInitialForecastData();
      getInitialGroupRevenueData();
      getOccupancyData();
      getInitialEventsData();
    }
  }, [formData, selectedRestaurant, selectedRestaurantId]);

  useEffect(()=>{
    if(forecastData){
      const temperatureList = setWeathersWidgetData(weatherData?.days, temperatures);
      setCurrentItems(prev =>
        prev.map(item => 
          item.name === "Weather" ? { ...item, ...temperatureList } : item
        )
      );
      setCurrentItems(prev =>
        prev.map(item =>
          item.name === "Occupancy" ? { ...item, ...occupancyData } : item
        )
      );
      if (eventsData && Object.keys(eventsData).length > 2) {
        setCurrentItems(prev =>
          prev.map(item =>
            item.name === "Events" ? { ...item, ...eventsData, loading : false } : item
          )
        );
      }
      if (foodcostData && Object.keys(foodcostData).length > 2) {
        setCurrentItems(prev =>
          prev.map(item =>
            item.name === "Foodcost" ? { ...item, ...foodcostData, loading : false } : item
          )
        );
      }
      if ((averageGroupRevenueData && Object.keys(averageGroupRevenueData).length > 2) || averageGroupRevenueData == undefined){
        setCurrentItems(prev =>
          prev.map(item =>
            item.name === "Average Group Revenue" ? { ...item, ...averageGroupRevenueData, loading : false } : item
          )
        );
      }
    }
  },[forecastData, weatherData, occupancyData, foodcostData, averageGroupRevenueData, eventsData])
  
  const formatData = ({ days, start_date, end_date }) => {
    const allDates = getDates(start_date, end_date);
    setDates(allDates);
    return (days ?? []).map((i) => {
      return {
       ...i
      };
    });
  };

  const generateTableColumns = (cols) => {
    const newTableColumns = [
      ...cols
        .map(({ caption, dataField, ...rest }) => ({
          ...rest,
          caption: caption,
          dataField,
        }))
    ];
    setTableColumns(newTableColumns);
  }

  const generateTableData = (resultData) => {
    generateTableColumns(cols);
    const transformedData = {};

    let updatedCurrentItems = resultData.map((i, index) => {
      const accuracy_revenue = (i.actual_revenue !== 0 && i.predicted_revenue !== 0) && 
        (i.actual_revenue - i.predicted_revenue) < i.actual_revenue
        ? Math.max(0, (1 - Math.abs((i.actual_revenue - i.predicted_revenue) / i.actual_revenue)) * 100)
        : 0;
      const columnName = COLUMNS[index % COLUMNS.length];
      return {
        ...i,
        accuracy : accuracy_revenue,
        name: columnName
      };
    })
    updatedCurrentItems = updatedCurrentItems.forEach(item => {
      Object.keys(item)?.forEach(key => {
          if (key !== 'date' && key !== 'name') {
              // If the key doesn't exist in transformedData, initialize it with an empty object
              if (!transformedData[key]) {
                  transformedData[key] = { name: key };
              }
              // Set the value for the current date
              transformedData[key][item.date] = item[key];
          }
      });
    });

    const result = Object.values(transformedData);

    // Sort the result based on the priorityOrder and name Mapping
    const updatedResult = priorityOrder.map(name => {
      const item = result.find(obj => nameMapping[obj.name] === name);
      if (item) {
        return {
          ...item,
          name: name 
        };
      }
      return null;
    }).filter(Boolean); 

    if(!updatedResult.length) {
      updatedResult.push({ name: 'Turnover Excl. tax'});
      updatedResult.push({ name: 'Forecast' });
      updatedResult.push({ name: 'Accuracy' });
    }
    setCurrentItems(prev => ([...updatedResult, { name: "Average Group Revenue", loading: true }, { name : "Foodcost", loading: true }, { name : "Occupancy" }, { name : "Weather" }, { name: "Events", loading: true }]));
  };

  const getInitialForecastData = async () => {
    try {
      setForecastData(false);
      let result = await getForecastData();
      generateTableData(formatData(result));
      setForecastData(true);
      setLoadingDetails(false);
    } catch (error) {
      console.log({error})
    } 
  };

  const getFoodcastData = async () => {
    try {
      await getFoodcast();
    } catch (error){
      console.log({error})
    }
  }

  const getOccupancyData = async () => {
    try {
      await getOccupancy();
    } catch (error) {
      console.log({error})
    } 
  }

  const getInitialGroupRevenueData = async () => {
    try {
      await getAverageGroupRevenueData();
    } catch (error) {
      console.log({error})
    } 
  };

  const getInitialEventsData = async () => {
    try {
      setEventLoader(true);
      await getEventsData();
      setEventLoader(false);
    } catch (error) {
      console.log({error})
    } 
  };

  const getWeatherData = async () => {
    setWeatherLoading(true);
    try {
      const weatherResponse = await request.get("weather", {
        start_date: formData?.start_date,
        end_date: formData?.end_date,
        location_id: selectedRestaurant?.location_id,
      });
      setWeatherData(weatherResponse);
    } catch (error) {
      console.error("Error fetching weather data:", error);
    } finally {
      setWeatherLoading(false);
    }
  };

  const getForecastData = async() =>{
    const start_date = formData?.start_date;
    const end_date = formData?.end_date;
    const result = await request.get(
      `forecast/revenue`,
      {
        restaurant_id: selectedRestaurantId,
        ...utcTimezoneFormat(
          start_date, 
          end_date, 
          selectedRestaurant?.timezone
        )
      }
    );
    const forecastByDays = forecastParseData(
      result?.days,
      selectedRestaurant?.timezone
    );
    const updatedData = calculateSumsPerDate(forecastByDays, result, ["actual_revenue", "predicted_revenue"]);
    return updatedData;
  }

  const getOccupancy = async () => {
    const start_date = formData?.start_date;
    const end_date = formData?.end_date;
    const transformedData = {};

    const result = await request.get(
      `forecast/occupancy`,
      {
        restaurant_id: selectedRestaurantId,
        ...utcTimezoneFormat(
          start_date, 
          end_date, 
          selectedRestaurant?.timezone
        )
      }
    );

    const forecastByDays = forecastParseData(
      result?.days,
      selectedRestaurant?.timezone
    );

    const updatedData = calculateSumsPerDate(forecastByDays, result, "occupancy");
    updatedData?.days.forEach(item => {
      Object.keys(item)?.forEach(key => {
          if (key !== 'date' && key !== 'name') {
              // If the key doesn't exist in transformedData, initialize it with an empty object
              if (!transformedData[key]) {
                  transformedData[key] = { name: key };
              }
              // Set the value for the current date
              transformedData[key][item.date] = item[key];
          }
      });
    });
    if (transformedData['occupancy']) {
      transformedData['occupancy'].name = "Occupancy";
    }
    const occupancy = Object.values(transformedData);
    setOccupancyData(occupancy[0]);
  }

  const getFoodcast = async () => {
    const start_date = formData?.start_date;
    const end_date = formData?.end_date;
    const transformedData = {};
    setFoodcostData([]);
    const result = await request.get(
      `forecast/foodcost`,
      {
        restaurant_id: selectedRestaurantId,
        ...utcTimezoneFormat(
          start_date, 
          end_date, 
          selectedRestaurant?.timezone
        )
      }
    );

    const forecastByDays = forecastParseData(
      result?.days,
      selectedRestaurant?.timezone
    );
    const updatedData = calculateSumsPerDate(forecastByDays, result, "foodcost");
    updatedData?.days.forEach(item => {
      Object.keys(item)?.forEach(key => {
          if (key !== 'date' && key !== 'name') {
              // If the key doesn't exist in transformedData, initialize it with an empty object
              if (!transformedData[key]) {
                  transformedData[key] = { name: key };
              }
              // Set the value for the current date
              transformedData[key][item.date] = item[key];
          }
      });
    });
    if (transformedData['foodcost']) {
      transformedData['foodcost'].name = "Foodcost";
    }
    const foodcost = Object.values(transformedData);
    setFoodcostData(foodcost[0]);
    // setCurrentItems(prev =>
    //   prev.map(item => (item.name === "Foodcost" ? { ...item, ...foodcost[0]} : item))
    // );
  }

  const getEventsData = async() => {
    const start_date = formData?.start_date;
    const end_date = formData?.end_date;
    setEventsData([]);
    const result = await request.get(
      `forecast/events`,
      {
        restaurant_id: selectedRestaurantId,
        start_date, 
        end_date
      }
    );
    let formattedEvents = { name: "Events" };
    result.events.forEach(day => {
      formattedEvents[day.date] = day?.events;
    });
    setEventsData(formattedEvents);
    // setCurrentItems(prev =>
    //   prev.map(item => (item.name === "Events" ? { ...item, ...formattedEvents, loading : false } : item))
    // );
  }

  const getAverageGroupRevenueData = async() => {
    const start_date = formData?.start_date;
    const end_date = formData?.end_date;
    const transformedData = {};
    setAverageGroupRevenueData([]);
    const result = await request.get(
      `forecast/average-group-revenue`,
      {
        restaurant_id: selectedRestaurantId,
        ...utcTimezoneFormat(
          start_date, 
          end_date, 
          selectedRestaurant?.timezone
        )
      }
    );

    const forecastByDays = forecastParseData(
      result?.days,
      selectedRestaurant?.timezone
    );
    const updatedData = calculateSumsPerDate(forecastByDays, result, "average_group_revenue");
    updatedData?.days.forEach(item => {
      Object.keys(item)?.forEach(key => {
          if (key !== 'date' && key !== 'name') {
              // If the key doesn't exist in transformedData, initialize it with an empty object
              if (!transformedData[key]) {
                  transformedData[key] = { name: key };
              }
              // Set the value for the current date
              transformedData[key][item.date] = item[key];
          }
      });
    });

    if (transformedData['average_group_revenue']) {
      transformedData['average_group_revenue'].name = "Average Group Revenue";
    }
    const averageGroupRevenue = Object.values(transformedData);
    setAverageGroupRevenueData(averageGroupRevenue[0]);
    // setCurrentItems(prev =>
    //   prev.map(item => (item.name === "Average Group Revenue" ? { ...item, ...averageGroupRevenue[0] } : item))
    // );
  }

  return (
    <div className="leftcontent forcast_left" style = {{ marginBottom: "-10px"}}>
      <div style={{display: "flex", justifyContent: "flex-end", gap:10, marginBottom:"20px"}}>
      <Button
          variant={activeWeek === 'previous' ? "primary" : undefined}
          style={
            activeWeek === 'previous'
              ? {}
              : {
                  backgroundColor: "#873CFC1A",
                  border: "1px solid #6353ea",
                  color: "#6353ea",
                }
          }
          onClick={onPreviousWeek}
        >
          {t("PreviousWeek")}
        </Button>

        <Button  
          variant={activeWeek === 'current' ? "primary" : undefined} 
          style={
            activeWeek === 'current'
              ? {}
              : {
                  backgroundColor: "#873CFC1A", 
                  border: "1px solid #6353ea",
                  color: "#6353ea",
                }
          }
          onClick={onCurrentWeek}
        >
          {t("CurrentWeek")}
        </Button>

        <Button
          variant={activeWeek === 'next' ? "primary" : undefined} 
          style={
            activeWeek === 'next'
              ? {}
              : {
                  backgroundColor: "#873CFC1A", 
                  border: "1px solid #6353ea",
                  color: "#6353ea",
                }
          }
          onClick={onNextWeek}
          disabled={isNextDisabled}
        >
          {t("NextWeek")}
        </Button>

      </div>
      <div className="card forecast-card">
        <div className="card-body inherit-height">
          {loadingDetails && (
            <div className="w-100 d-flex justify-content-center card-spinner-container">
              <Spinner animation="border" variant="primary" />
            </div>
          )} 

          {!loadingDetails && (
            <CustomWidget
              className="forecast-table"
              columns={tableColumns}
              data={currentItems}
              loading={eventLoader}
              tableName="forecast"
              startDate={formData.start_date}
              endDate={formData.end_date}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(Forecast);
