import moment from "moment";
import { isBetweenTime, cloneDeep } from "common/utils.ts";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import clearDayIcon from "assets/images/weather/sunny.svg";
import partlyCloudyNightIcon from "assets/images/weather/night.svg";
import partlyCloudyDayIcon from "assets/images/weather/suncloud.svg";
import cloudyIcon from "assets/images/weather/cloud.svg";
import rainIcon from "assets/images/weather/rain.svg";

const weatherIcons = {
  cloudy: cloudyIcon,
  rain: rainIcon,
  "partly-cloudy-day": partlyCloudyDayIcon,
  "partly-cloudy-night": partlyCloudyNightIcon,
  "clear-day": clearDayIcon,
  // icons for below conditions are not available yet, so for the moment show the more relevant ones
  "clear-night": partlyCloudyNightIcon,
  "snow": rainIcon,
  "fog": cloudyIcon,
  "wind": cloudyIcon
};

export const COLUMNS = ["Revenue", "Predicted revenue", "Accuracy", "Foodcost", "Average group revenue", "Occupancy", "Weather", "Events"];

export const generateDates = (startDate, endDate) => {
  const dates = [];
  let currentDate = new Date(startDate);
  const end = new Date(endDate);

  while (currentDate <= end) {
    dates.push(currentDate.toISOString().split('T')[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

export const getRandomValue = (originalValue, min, max) => {
  const randomFactor = Math.random() * (max - min) + min;
  return originalValue + randomFactor * (Math.random() < 0.5 ? -1 : 1);
};

export const updateValues = (item, originalDates, today, dynamicDates) => {
  const updatedItem = { name: item.name };

  dynamicDates.forEach((date, index) => {
    if (originalDates[index] !== undefined) {
      const originalValue = item[originalDates[index]];

      if (date >= today && (item.name === "Turnover Excl. tax" || item.name === "Accuracy")) {
        updatedItem[date] = 0;
      } else {
        if (item.name === "Accuracy") {
          updatedItem[date] = getRandomValue(originalValue, 3, 5);
        } else if (item.name !== "Weather" && item.name !== "Events") {
          updatedItem[date] = getRandomValue(originalValue, 50, 200);
        } else {
          updatedItem[date] = originalValue;
        }
      }
    } else {
      updatedItem[date] = date >= today && (item.name === "Turnover Excl. tax" || item.name === "Accuracy") ? 0 : null;
    }
  });

  return updatedItem;
};

export const forecastParseData = (days, timezone) => {
  const keys = days?.length && Object.keys(days[0])
  const newDays = [];
  days?.forEach(({date, details}) => {
    details?.forEach(({ interval: intervalz, ...rest}) => {
      const currentInterval = `${date}T${intervalz}Z`;
      const getIntervalTz = () => moment.tz(currentInterval, timezone);
      const dateTz = getIntervalTz().format(`YYYY-MM-DD`);
      const interval = getIntervalTz().format(`HH:mm:ss`);
      const result = newDays.find((x) => x.date === dateTz);
      if (!result) {
        newDays.push({ date: dateTz, [keys[1]]: [{ ...rest, interval }]});
      } else {
        result[keys[1]]?.push({ ...rest, interval});
      }
    });
  });
  return newDays;
};

export const getDates = (startDate, endDate) => {
  const dates = [];
    let currentDate = moment(startDate); 

    while (currentDate <= moment(endDate)) {
        dates.push(currentDate.format('YYYY-MM-DD'));
        currentDate = currentDate.clone().add(1, 'day'); 
    }
    return dates;
}

const formatDate = (dateString, t) => {
  const date = moment(dateString);
  const day = t(date.format("ddd")); 
  const dayOfMonth = date.format("DD"); 

  return `${day} ${dayOfMonth}`;
};

const renderTooltip = (value) => (props) => 
  (
    <Tooltip id="button-tooltip" {...props}>
      {value}
    </Tooltip>
  );

export const nameMapping = {
  "actual_revenue": "Turnover Excl. tax",
  "predicted_revenue": "Forecast",
  "accuracy": "Accuracy",
  "foodcost": "Foodcost",
  "average_group_revenue": "Average Group Revenue",
  "occupancy": "Occupancy",
  "weather": "Weather",
  "events": "Events"
};

// Prioritize the data based on the priorityOrder
export const priorityOrder = [
  "Turnover Excl. tax",
  "Forecast",
  "Accuracy",
  "Foodcost",
  "Average Group Revenue",
  "Occupancy",
  "Weather",
  "Events"
];

const getWeatherIcon = (icon) =>
  weatherIcons[icon] ?? weatherIcons["clear-day"];

export const getForecastColumns = (t, dates) => {
    const renderWeatherColumn = (column, item, dataIndex) => {
      const dateValue = item[column?.dataField];
      const currentDate = moment();
      const columnDate = moment(dates[dataIndex]);
      const isToday = columnDate.isSame(currentDate, 'day');
      const fontWeight = dataIndex === 3 && isToday ? "800" : "400";
      if (!Array.isArray(dateValue)) return null;

      const morningTemp = dateValue[0]?.value;
      const morningIcon = dateValue[0]?.icon;
      const eveningTemp = dateValue[2]?.value;
      const eveningIcon = dateValue[2]?.icon;

      return (
        <div>
          <strong className="text-center forecast-values" style={{ fontWeight }}>
            {isNaN(morningTemp) || isNaN(eveningTemp) ? "--" : (
              <>
                <div>                      
                  <p>{morningTemp}° </p>
                  <img src={getWeatherIcon(morningIcon)} alt={morningIcon} style={{ width: "30px", height: "30px" }} />
                </div>
                <div style={{
                  borderLeft: "2px solid #E0E0E0",
                  height: "50px",
                  margin: "0 10px"
                }}></div>
                <div>
                  <p>{eveningTemp}° </p>
                  <img src={getWeatherIcon(eveningIcon)} alt={eveningIcon} style={{ width: "30px", height: "30px" }} />
                </div>
              </>
            )}
          </strong>
        </div>
      );
    };

    const renderValueColumn = (column, item, dataIndex) => {
      const currentDate = moment(); 
      const columnDate = moment(dates[dataIndex]);
      const isFutureOrPresent = columnDate.isAfter(currentDate, 'day');
      const colorStyle = isFutureOrPresent ? { color: "rgb(160,160,160)" } : { color: "#000000" };
      const value = item[dates[dataIndex]];
      const isToday = columnDate.isSame(currentDate, 'day');
      const fontWeight = dataIndex === 3 && isToday ? "700" : "400";
      const fontSize = dataIndex === 3 && isToday ? "22px" : "16px";

      if (item.name == "Forecast") {
        return (
          <div className="forecast-values" style={{ fontSize, fontWeight, height: "35px", width: "110px", color: "#873CFC", boxShadow: "0px 1px 2px 1px #00000040", borderRadius: "3px", margin: "auto"}}>
            {item.loading ? "--" : (typeof value === 'number' && value !== 0 ? `€${value.toFixed(2)}` : null)}
          </div>
        );
      }

      if (item.name == "Average Group Revenue") {
        return (
          <div className="forecast-values" style={{ fontSize, fontWeight, ...colorStyle }}>
            {item.loading ? "--" : (typeof value === 'number' ? `€${value.toFixed(2)}` : null)}
          </div>
        );
      }

      if (item.name == "Foodcost") {
        return (
          <div className="forecast-values" style={{ fontSize, fontWeight, ...colorStyle }}>
            {item.loading ? "--" : (typeof value === 'number' ? `€${value.toFixed(2)}` : null)}
          </div>
        );
      }

      if (item.name === "Events" || !value) return null;

      if (item.name === "Weather") {
        return renderWeatherColumn(column, item, dataIndex);
      }

      if (item.name === "Accuracy") {
        return (
          <div className={value >= 75 ? "text-green forecast-values" : value >= 65 ? "text-orange forecast-values" : "text-danger forecast-values"} style={{ fontSize: "16px", fontWeight, ...colorStyle }}>
            {item[dates[dataIndex]]?.toFixed(2)} %
          </div>
        );
      }

      if (item.name === "Occupancy") {
        return (
          <div className="forecast-values" style={{ fontSize, fontWeight, ...colorStyle }}>
            {new Intl.NumberFormat().format(value?.toFixed(2))}
          </div>
        );
      }
    
      return (
        <div className="forecast-values" style={{ fontSize, fontWeight, ...colorStyle }}>
          {typeof value === 'number' ? `€${value.toFixed(2)}` : "--"}
        </div>
      );
    };

    const columns = dates.map((date, index) => {
      const currentDate = moment(); 
      const columnDate = moment(date); 
      const isToday = columnDate.isSame(currentDate, 'day');
      const fontSize = index === 3 && isToday ? "22px" : "16px";
      return {
        dataField: date,
        caption: (
          <OverlayTrigger
            placement="top"
            overlay={renderTooltip(formatDate(date, t))}
          >
            <span className="forecast-values" style={{fontSize, fontWeight: 700, color: index === 3 && isToday ? "#873CFC" : "#000000" }}>{formatDate(date, t)}</span>
          </OverlayTrigger>
        ),
        className: "fw-bold",
        style: { width: "150px", fontSize: "16px", fontWeight: index === 3 ? "800 !important" : "400 !important",},
        headerStyle: { width: "150px" },
        type: "customRender",
        custom: "events",
        render: (column, item) => renderValueColumn(column, item, index),
    }});

    columns.unshift({
      dataField: "name",
      caption: (
        <OverlayTrigger
          placement="top"
          overlay={renderTooltip("KPI's")}
        >
          <span style={{ fontSize: "16px", color: "#000000", fontWeight: "700" }}>{t("KPI's")}</span>
        </OverlayTrigger>
      ),
      className: "fw-bold",
      style: { width: "150px", fontSize: "16px", fontWeight: "700", backgroundColor: "#F8F9FD" },
      headerStyle: { width: "150px" },
      type: "customRender",
      render: (column, item) => (
        <span style={{ fontSize: "16px", color: "#000000", fontWeight: "700" }}>
          {t(item?.name)}
        </span>
      ),
    });

  return columns;
};

export const DAY_TIMES = {
  morning: ["04:00", "12:00"],
  noon: ["10:00", "18:00"],
  night: ["16:00", "23:59"],
};

const DAY_ICON_TIMES = {
  morning: "08:00:00",
  noon: "14:00:00",
  night: "23:00:00",
};

export const DAY_TIMES_TIME = {
  breakfast: ["00:00", "10:59"],
  lunch: ["11:00", "14:59"],
  afternoon: ["15:00", "17:59"],
  dinner: ["18:00", "23:59"],
};

export const manipulateDailyOccupancy = (selectedRestaurant, result) => {
  try {
    const timezone = selectedRestaurant?.timezone ?? "America/Vancouver";
    const currentDateData = [];
    const matchDate = moment
      .tz(new Date(), timezone)
      .subtract(2, "days")
      .format("YYYY-MM-DD");

    const responseDate = (date, interval) =>
      moment.tz(date.replace("00:00:00", interval), timezone);

    result.days.forEach((day) => {
      const occupancyD = day.occupancy.forEach((occupancy) => {
        const timeZoneDate = responseDate(day.date, occupancy.interval);
        const responseResult = timeZoneDate.format("YYYY-MM-DD") === matchDate;
        if (responseResult) {
          currentDateData.push({
            ...occupancy,
            intervalZ: timeZoneDate.format("HH:mm:ss"),
          });
        }
      });
      return { ...day, occupancy: occupancyD };
    });
    return { ...result, days: [{ occupancy: currentDateData }] };
  } catch (error) {}
};

/**
 * To get first five hours temperature of next day.
 * @param {*} hours
 * @returns {Array}
 */
export const nextDayFiveHoursTemp = (hours) =>
  hours
    .filter((h) => isBetweenTime(h.time, "00:00", "05:00"))
    .map((h) => ({ temp: parseFloat(h.temp), time: h.time }));

export const setWeatherWidgetData = (days, temperatures) => {
  try {
    const times = {};
    const [currentDay, nextDay] = days ?? [];
    if (!currentDay || !nextDay) {
      return [];
    }
    
    Object.keys(DAY_TIMES).forEach((it) => {
      const [startTime, endTime] = DAY_TIMES[it];
      let selectedHours = currentDay.hours
        .filter((ele) => isBetweenTime(ele.time, startTime, endTime))
        .map((h) => ({ temp: parseFloat(h.temp), time: h.time }));

      if (it === "night") {
        selectedHours = [
          ...(selectedHours ?? []),
          ...(nextDay.hours ? nextDayFiveHoursTemp(nextDay.hours) : []),
        ];
      }
      times[it] =
        selectedHours.reduce((acc, obj) => acc + obj.temp, 0) /
        selectedHours.length;
    });
    const newTemperatures = cloneDeep(temperatures);
    Object.keys(DAY_TIMES).forEach((ele) => {
      const isExist = newTemperatures.find((t) => t.name === ele);
      if (isExist) {
        isExist.value = times[ele].toFixed(1);
        isExist.icon = currentDay.hours.find((h) => h.time === DAY_ICON_TIMES[ele])?.icon;
      }
    });
    return newTemperatures;
  } catch (error) {
    console.log(error);
    throw error;
  }
};


export const calculateSumsPerDate = (forecastByDays, result, keys) => {
  const keysArray = Array.isArray(keys) ? keys : [keys];

  const updatedResult = forecastByDays.map((day) => ({
    date: day.date,
    details: day.details,
  }));

  const sumsPerDate = {};

  updatedResult?.forEach(({ date, details }) => {
    if (!sumsPerDate[date]) {
      sumsPerDate[date] = { date };
      keysArray.forEach(key => {
        sumsPerDate[date][key] = 0;
      });
    }

    details?.forEach((detail) => {
      for (const detailKey in sumsPerDate[date]) {
        if (detail[detailKey] !== null && !isNaN(detail[detailKey])) {
          sumsPerDate[date][detailKey] += detail[detailKey];
        }
      }
    });
  });

  const sumsPerDateArray = Object.values(sumsPerDate);

  return {
    ...result,
    days: sumsPerDateArray,
  };
};

export const setWeathersWidgetData = (days, temperatures) => {
  try {
    const result = {};

    days?.forEach((day) => {
      const date = day.date; 
      const temperatureData = [];

      Object.keys(DAY_TIMES).forEach((it) => {
        const [startTime, endTime] = DAY_TIMES[it];
        let selectedHours = day.hours
          .filter((ele) => isBetweenTime(ele.time, startTime, endTime))
          .map((h) => ({ temp: parseFloat(h.temp), time: h.time }));

        // If it's the "night" time, also include the first five hours of the next day
        if (it === "night") {
          const nextDay = days.find(next => next.date !== day.date);
          if (nextDay) {
            selectedHours = [
              ...selectedHours,
              ...(nextDay.hours ? nextDay.hours.slice(0, 5).map((h) => ({ temp: parseFloat(h.temp), time: h.time })) : []),
            ];
          }
        }

        // Calculate average temperature for the time slot
        const avgTemp = selectedHours.reduce((acc, obj) => acc + obj.temp, 0) / selectedHours.length;
        temperatureData.push({
          name: it,
          value: avgTemp.toFixed(1),
          icon: day.hours.find((h) => h.time === DAY_ICON_TIMES[it])?.icon
        });
      });

      result[date] = temperatureData;
    });

    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const dummyForecastAnalytics = () => ({
  average_ticket: 341,
  total_guests: 13247,
  total_revenue: 95.38,
  average_minutes_spent: 94,
});

export const getSampleOcuupancyForecast = () => {
  return {
    days: [
      {
        date: moment()
          .format("YYYY-MM-DD"),
        occupancy: [
          {
            interval: "05:00:00",
            total_guests: 7,
          },
          {
            interval: "05:30:00",
            total_guests: 2,
          },
          {
            interval: "06:00:00",
            total_guests: 1,
          },
          {
            interval: "06:30:00",
            total_guests: 7,
          },
          {
            interval: "07:00:00",
            total_guests: 2,
          },
          {
            interval: "07:30:00",
            total_guests: 1,
          },
          {
            interval: "08:00:00",
            total_guests: 21
          },
          {
            interval: "08:30:00",
            total_guests: 12
          },
          {
            interval: "09:00:00",
            total_guests: 10
          },
          {
            interval: "09:30:00",
            total_guests: 4
          },
          {
            interval: "10:00:00",
            total_guests: 3
          },
          {
            interval: "10:30:00",
            total_guests: 0
          },
          {
            interval: "11:00:00",
            total_guests: 2
          },
          {
            interval: "11:30:00",
            total_guests: 3
          },
          {
            interval: "12:00:00",
            total_guests: 9
          },
          {
            interval: "12:30:00",
            total_guests: 13,
          },
          {
            interval: "13:00:00",
            total_guests: 19
          },
          {
            interval: "13:30:00",
            total_guests: 9,
          },
          {
            interval: "14:00:00",
            total_guests: 5,
          },
          {
            interval: "14:30:00",
            total_guests: 8,
          },
          {
            interval: "15:00:00",
            total_guests: 10,
          },
          {
            interval: "15:30:00",
            total_guests: 20,
          },
          {
            interval: "16:00:00",
            total_guests: 28,
          },
          {
            interval: "16:30:00",
            total_guests: 43,
          },
          {
            interval: "17:00:00",
            total_guests: 13,
          },
          {
            interval: "17:30:00",
            total_guests: 35,
          },
          {
            interval: "18:00:00",
            total_guests: 23,
          },
          {
            interval: "18:30:00",
            total_guests: 20,
          },
          {
            interval: "19:00:00",
            total_guests: 30,
          },
          {
            interval: "19:30:00",
            total_guests: 33,
          },
          {
            interval: "20:00:00",
            total_guests: 28,
          },
          {
            interval: "20:30:00",
            total_guests: 38,
          },
          {
            interval: "21:00:00",
            total_guests: 19,
          },
          {
            interval: "21:30:00",
            total_guests: 79,
          },
          {
            interval: "22:00:00",
            total_guests: 4,
          },
          {
            interval: "22:30:00",
            total_guests: 8,
          },
          {
            interval: "23:00:00",
            total_guests: 15,
          },
          {
            interval: "23:30:00",
            total_guests: 22,
          },
        ],
      },
      {
        date: moment()
          .add(1, "days")
          .format("YYYY-MM-DD"),
        occupancy: [
          {
            interval: "00:00:00",
            total_guests: 8,
          },
          {
            interval: "00:30:00",
            total_guests: 14,
          },
          {
            interval: "01:00:00",
            total_guests: 17,
          },
          {
            interval: "01:30:00",
            total_guests: 30,
          },
          {
            interval: "02:00:00",
            total_guests: 26,
          },
          {
            interval: "02:30:00",
            total_guests: 20,
          },
          {
            interval: "03:00:00",
            total_guests: 16,
          },
          {
            interval: "03:30:00",
            total_guests: 11,
          },
          {
            interval: "04:00:00",
            total_guests: 9,
          },
          {
            interval: "04:30:00",
            total_guests: 7,
          },
        ],
      }
    ]
  };
};

export function getForcastColumns(
  t
) {
  const arr = [];

  return [
    {
      dataField: "name",
      caption: t("KPI's"),
      className: "fw-bold",
      style: { width: "150px" },
      headerStyle: { width: "150px" },
    },
    {
      dataField: "revenue",
      caption: t("Thu 23"),
      className: "text-center",
      headerClassName: "text-center",
    },
    {
      dataField: "predicted_revenue",
      caption: `${t("Fri 24")}`,
      className: "text-center",
      headerClassName: "text-center",
    },
    {
      dataField: "accuracy",
      caption: `${t("Sat 25")}`,
      className: "text-center",
      headerClassName: "text-center",
    },
    {
      dataField: "average_group_revenue",
      caption: `${t("Sun 26")}`,
      className: "text-center",
      headerClassName: "text-center",
    },
    {
      dataField: "weather",
      caption: `${t("Mon 27")}`,
      className: "text-center",
      headerClassName: "text-center",
    },
    {
      dataField: "events",
      caption: `${t("Tue 28")}`,
      className: "text-center",
      headerClassName: "text-center",
    },
    {
      dataField: "no_of_clients",
      caption: `${t("Wed 29")}`,
      className: "text-center",
      headerClassName: "text-center",
    },
  ];
}


